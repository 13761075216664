import * as React from 'react'

import { zonedTimeToUtc } from 'date-fns-tz'
import diff from 'date-fns/differenceInMilliseconds'
import addSeconds from 'date-fns/addSeconds'
import { Countdown } from 'components/Countdown'
const CommingDraw = ({ draw, index, onComplete, timestamp }) => {
  const { draw_date } = draw
  const diffDate = diff(new Date(), zonedTimeToUtc(timestamp))
  const drawDate = addSeconds(zonedTimeToUtc(draw_date), diffDate / 1000)

  return (
    <Countdown timestamp={timestamp} date={drawDate} onComplete={onComplete} />
  )
}

export { CommingDraw }
