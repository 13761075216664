const getBallotSize = () => {
  const windowSize = window.screen.width

  switch (true) {
    case windowSize >= 768:
      return 50
    case windowSize < 768 && windowSize > 425:
      return 35
    case windowSize <= 425 && windowSize > 320:
      return 30
    default:
      return 25
  }
}

export default getBallotSize
