export const data = [
  {
    words: "Abattoir , labatwa, Slaughterhouse",
    results: "16.69.31.43"
  },
  {
    words: "Abeilles , my\u00e8l, Honey",
    results: "96.06.08.49"
  },
  {
    words: "Aboiement  , abwaman, Bark",
    results: "31"
  },
  {
    words: "Accident , Aksidan, Accident",
    results: "96.06.05.49"
  },
  {
    words: "Accouchement, akouchman, Childbirth",
    results: "32.56.11.33"
  },
  {
    words: "Achat , achte, Purchased",
    results: "55.07.76.36"
  },
  {
    words: "Adult\u00e8re, Adilt\u00e8, Adultery",
    results: "29.58.69.28"
  },
  {
    words: "Aiguille, zegwi, Needle",
    results: "38.11.07.17"
  },
  {
    words: "Allaiter, bay tete, Breast-feed",
    results: "19"
  },
  {
    words: "Allumette, Alim\u00e8t, Matches",
    results: "11.08.42"
  },
  {
    words: "Altagrace",
    results: "12.21"
  },
  {
    words: "Ambulance , anbilans",
    results: "30.37.22.42"
  },
  {
    words: "Amour, Renmen, Love",
    results: "12.41"
  },
  {
    words: "Ananas, Anana, Pineapple",
    results: "73.19.11"
  },
  {
    words: "Argent monnaie , Lajan monnen, Coins",
    results: "11.78"
  },
  {
    words: "Argent papier , Lajan papye, Money",
    results: "18.11.74"
  },
  {
    words: "Arreter, Sispann, Quit",
    results: "36.13.24.25"
  },
  {
    words: "Atteler , Asosye, Harness",
    results: "41.47.45.73"
  },
  {
    words: "Automobile, Otomobil, Car",
    results: "15.77.00.33"
  },
  {
    words: "Avion, Avyon, Airplane",
    results: "03.29.47.85.04"
  },
  {
    words: "Bague de Mariage, Bag maryaj, Wedding ring",
    results: "25.59"
  },
  {
    words: "Bain, beny, Bath",
    results: "32.25.59"
  },
  {
    words: "Baiser, Bo, Kiss",
    results: "23.14.22.05"
  },
  {
    words: "Balai, Bale, Broom",
    results: "55.03.14"
  },
  {
    words: "Balles, Boul, Balls",
    results: "14.82.46.26"
  },
  {
    words: "Banane, Bannann, Banana",
    results: "87.48"
  },
  {
    words: "Banque, Bank",
    results: "61.15.84.14"
  },
  {
    words: "Bapteme, Bat\u00e8m, Baptizing",
    results: "88.69.58"
  },
  {
    words: "Bataille, Batay, Fight",
    results: "78.11.19.32"
  },
  {
    words: "Bateau, bato, Boat",
    results: "18.68"
  },
  {
    words: "Baton, Baton",
    results: "43.61"
  },
  {
    words: "Baton , Baton",
    results: "89.40.41.88"
  },
  {
    words: "Bazar, baza, Store",
    results: "39.62"
  },
  {
    words: "Berceau, b\u00e8so, Crib",
    results: "01.36.62.72"
  },
  {
    words: "Biberon, Bibon, Baby bottle",
    results: "82.94"
  },
  {
    words: "Bible , Bib",
    results: "32.2"
  },
  {
    words: "Bicyclette, Bisikl\u00e8t, Bicycle",
    results: "52.4178"
  },
  {
    words: "Bijoux, bijou, Jewelry",
    results: "53.41.52"
  },
  {
    words: "Blessure, Blese, Injury",
    results: "09.03.42.62"
  },
  {
    words: "Son de Bl\u00e9, Son de ble, Bran",
    results: "01.79.09.83"
  },
  {
    words: "Boite, Bwat, Box",
    results: "54. 14. 88. 06. 53. 27. 42. 51"
  },
  {
    words: "Seau , Bokit, Bucket",
    results: "49"
  },
  {
    words: "Boue, labou, Mud",
    results: "06.60.62.82"
  },
  {
    words: "Bougie, bouji, Candle",
    results: "65.04.59"
  },
  {
    words: "Boulanger, boulanje, Baker",
    results: "03.21.32.60"
  },
  {
    words: "Mule, Bourik, Donkey",
    results: "53.35.91.14"
  },
  {
    words: "Bourse, Bous, Wallet",
    results: "02.60.10.69"
  },
  {
    words: "Brouette, bwou\u00e8t, Wheelbarrow",
    results: "41"
  },
  {
    words: "Viande de Boeuf , vyann b\u00e8f, Beef",
    results: "16.76.96"
  },
  {
    words: "Cabri, Kabrit, Goat",
    results: "28.82"
  },
  {
    words: "Caca, Twal\u00e8t, Shit",
    results: "07"
  },
  {
    words: "Cadeau, kado, Present",
    results: "41.11.30.05"
  },
  {
    words: "Cadenas, Kadna, Padlock",
    results: "97.61.22.05"
  },
  {
    words: "Caf\u00e9, Kafe, Coffe",
    results: "68.27.06"
  },
  {
    words: "Cahier, Kaye, Notebook",
    results: "32"
  },
  {
    words: "Canard, kanna, Duck",
    results: "41.42.22"
  },
  {
    words: "Carnaval, Kanaval, Carnival",
    results: "66.67"
  },
  {
    words: "Caserne, Kaz\u00e8n, Barrack",
    results: "00.56.31"
  },
  {
    words: "Cath\u00e9darle, Katedral, Cathedral",
    results: "64.16.08.21"
  },
  {
    words: "Caiman , Kayiman",
    results: "29.90.60"
  },
  {
    words: "Cerceuil, S\u00e8k\u00e8y, Coffin",
    results: "22.31"
  },
  {
    words: "Cerf-volant, Kap, Kite",
    results: "32.03.21.04"
  },
  {
    words: "Chaine, Ch\u00e8n, Chain",
    results: "42.73"
  },
  {
    words: "Chaise, Ch\u00e8z, Chair",
    results: "63.16.73"
  },
  {
    words: "Chanter, Chante, Sing",
    results: "32.85"
  },
  {
    words: "Chapeau, Chapo, Hat",
    results: "20.28.71.11"
  },
  {
    words: "Chapelet, Chapl\u00e8, Rosary",
    results: "04.32"
  },
  {
    words: "Charbon, Chabon, Coal",
    results: "85.59.07.30"
  },
  {
    words: "Chasseur, Chas\u00e8, Hunter",
    results: "09.59.99"
  },
  {
    words: "Chat, Chat, Cat",
    results: "74.04.14.84"
  },
  {
    words: "Chef , lid\u00e8 , Otorite, Leader",
    results: "22"
  },
  {
    words: "Chien, Chen, Dog",
    results: "15.75.95"
  },
  {
    words: "Ciel, Sy\u00e8l la, Sky",
    results: "66.89"
  },
  {
    words: "Cimeti\u00e8re, Simity\u00e8, Cemetery",
    results: "03.13"
  },
  {
    words: "Cin\u00e9ma, Sinema, Movie",
    results: "75"
  },
  {
    words: "Clairin, Kleren, Rhum",
    results: "49.14.40.36"
  },
  {
    words: "Clef, Kle, Key",
    results: "05.64.41"
  },
  {
    words: "Cloche, kl\u00f2ch, Bell",
    results: "03.27.48.25"
  },
  {
    words: "Clou, klou, Stud",
    results: "67.27.22"
  },
  {
    words: "Cochon, kochon, Pig",
    results: "58.32.22"
  },
  {
    words: "Commerce, Kom\u00e8s",
    results: "74.77"
  },
  {
    words: "Conduire, Mennen, Drive",
    results: "03.21.62"
  },
  {
    words: "Construction, konstriksyon",
    results: "32.19"
  },
  {
    words: "Coq, K\u00f2k, Rooster",
    results: "11.71.01.32"
  },
  {
    words: "Coq Bataille , k\u00f2k batay",
    results: "11.15.64"
  },
  {
    words: "Corbillard, K\u00f2bya, Hearse",
    results: "04.22.61"
  },
  {
    words: "Costume, Kostim, Suit",
    results: "44.23.84"
  },
  {
    words: "Couleuvre, Z\u00e8b-koul\u00e8v, Snake",
    results: "39.21.72"
  },
  {
    words: "Coup, Kou, Hit",
    results: "08.06"
  },
  {
    words: "Couteau, Kouto, Knife",
    results: "58.54.37.09"
  },
  {
    words: "Crabe, krab, Crab",
    results: "55.03"
  },
  {
    words: "Craie, Lakr\u00e8, Chalk",
    results: "28.19.46.62"
  },
  {
    words: "Crapaud, krapo, Toad",
    results: "22.0975"
  },
  {
    words: "Crapeau, Krapo, Toad",
    results: "28.73"
  },
  {
    words: "Crayon, Kreyon, Pencil",
    results: "01.11"
  },
  {
    words: "Crevette, Kribich, Shrimp",
    results: "30.31.32"
  },
  {
    words: "Cruche, Krich, Jug",
    results: "06.42.81.28"
  },
  {
    words: "Crucifix, Kwa, Cross",
    results: "10.40.33"
  },
  {
    words: "Cuisine, Kizin, Kitchen",
    results: "24"
  },
  {
    words: "Cuvette, b\u00f2l, Bowl",
    results: "30.14.64"
  },
  {
    words: "C\u00e9r\u00e9monie, Seremoni, Ceremony",
    results: "2.07"
  },
  {
    words: "Danse, Dans, Dance",
    results: "79.08.86.39"
  },
  {
    words: "Dent, Dan, Toothes",
    results: "31.58.15"
  },
  {
    words: "Drapeau , drapo",
    results: "77.73.14.24"
  },
  {
    words: "Eau , dlo",
    results: "89.22.05.51"
  },
  {
    words: "Ecatier , Ekatye",
    results: "23.32"
  },
  {
    words: "Echelle , ech\u00e8l",
    results: "65.03.25.62"
  },
  {
    words: "Ecrire , ekri",
    results: "07.21.32"
  },
  {
    words: "Egarer , P\u00e8di",
    results: "51.54.74"
  },
  {
    words: "Eglise , Legliz",
    results: "95.75.18"
  },
  {
    words: "Election , eleksyon",
    results: "68"
  },
  {
    words: "Enceinte , ansent",
    results: "20"
  },
  {
    words: "Enfants , timoun",
    results: "04.13.25"
  },
  {
    words: "Ennemis , l\u00e8nmi",
    results: "60.04.73.32"
  },
  {
    words: "Enterrement , fineray",
    results: "09.91.10.66"
  },
  {
    words: "Epingle , Epeng",
    results: "73.06"
  },
  {
    words: "Ep\u00e9e , epe",
    results: "62.14.96"
  },
  {
    words: "Escalier , eskalye",
    results: "6.36"
  },
  {
    words: "Etoile , Zetwal",
    results: "82.12.22.04"
  },
  {
    words: "Etudier , etidye",
    results: "57.73"
  },
  {
    words: "Fatras , Fatra",
    results: "85.86"
  },
  {
    words: "Femme , fanm",
    results: "12.05.50.42"
  },
  {
    words: "Fer \u00e0 repasser , Kawo",
    results: "44.16"
  },
  {
    words: "Gain au Jeu , Gany",
    results: "82.54.26"
  },
  {
    words: "Gateau , Gato",
    results: "52.48.50"
  },
  {
    words: "Gendarme , jandam",
    results: "25.50.03.49"
  },
  {
    words: "Glace , glas",
    results: "32.92.22"
  },
  {
    words: "Guerre , lag\u00e8",
    results: "95.36"
  },
  {
    words: "Guitare , gita",
    results: "83.31.75.89"
  },
  {
    words: "Hache , rach",
    results: "36.10.32"
  },
  {
    words: "Haillons , ranyon",
    results: "57.53"
  },
  {
    words: "Herbes , rem\u00e8d f\u00e8y",
    results: "89.12.43"
  },
  {
    words: "Homme , nonm",
    results: "19.02.11.91"
  },
  {
    words: "Hopital , lopital",
    results: "60.42.32"
  },
  {
    words: "Hotel , Hotel",
    results: "42.32.44.69"
  },
  {
    words: "Hougan , hougan",
    results: "37"
  },
  {
    words: "Image , imaj",
    results: "66.79.57"
  },
  {
    words: "Immacul\u00e9e , Manman Mari",
    results: "50.12.08"
  },
  {
    words: "Imprimerie , Enprimri",
    results: "07.41.73.30"
  },
  {
    words: "Incident , ensidan",
    results: "04.27.61.83"
  },
  {
    words: "Injures , joure",
    results: "19.41.05.47"
  },
  {
    words: "Injustice , enjistis",
    results: "82.61"
  },
  {
    words: "Inondation , inondasyon",
    results: "32.85"
  },
  {
    words: "Invasion , envazyon",
    results: "01.51.20"
  },
  {
    words: "Ivre , Sou",
    results: "10.32.51"
  },
  {
    words: "Jalousie , jalouzi",
    results: "05.22.80"
  },
  {
    words: "Jardin , jaden",
    results: "41.01.32"
  },
  {
    words: "Jarre , bokal",
    results: "31.86"
  },
  {
    words: "Jouet , jw\u00e8t",
    results: "14.52.21.62"
  },
  {
    words: "Journal , jounal",
    results: "96.21.62"
  },
  {
    words: "Juge , jij",
    results: "42.89.51"
  },
  {
    words: "Jupon , Jipon",
    results: "80.95"
  },
  {
    words: "Kermesse , F\u00e8t",
    results: "30"
  },
  {
    words: "Kodak , kodak",
    results: "86.04"
  },
  {
    words: "Kola , Kola",
    results: "16.85"
  },
  {
    words: "Labourer , Laboure",
    results: "01.29.41.91"
  },
  {
    words: "Laine , lenn mouton",
    results: "04.45.78"
  },
  {
    words: "Lait , l\u00e8t",
    results: "75.72.13"
  },
  {
    words: "Latrines , latrin",
    results: "5.81"
  },
  {
    words: "Laver , lave",
    results: "30.66.28"
  },
  {
    words: "Lessive , lesiv",
    results: "3.3"
  },
  {
    words: "Lettre , l\u00e8t",
    results: "66.12"
  },
  {
    words: "Linge , lave",
    results: "27.43.65.75"
  },
  {
    words: "Lion, lionne , Lion Lion",
    results: "4.42"
  },
  {
    words: "Lire , li",
    results: "07.06.42"
  },
  {
    words: "Lit , kabann",
    results: "57.59.46"
  },
  {
    words: "Livre , liv",
    results: "43"
  },
  {
    words: "Lougarou , lougarou",
    results: "37.47"
  },
  {
    words: "Lumi\u00e8re , limy\u00e8",
    results: "28.80.42"
  },
  {
    words: "Lune , Lalin",
    results: "15.41.17.06"
  },
  {
    words: "Lunette , Lin\u00e8t",
    results: "88.85.52"
  },
  {
    words: "L\u00e9gumes , legim",
    results: "47.29"
  },
  {
    words: "Magasin , boutik",
    results: "37.22"
  },
  {
    words: "Magistrat , majistra",
    results: "00.50.21"
  },
  {
    words: "Maitresse , Fanm dey\u00f2",
    results: "13.22.00"
  },
  {
    words: "Malade , malad",
    results: "66.67.58.75"
  },
  {
    words: "Manger , manje",
    results: "57.4"
  },
  {
    words: "Marchand , konsesyon\u00e8",
    results: "7"
  },
  {
    words: "March\u00e9 , mache",
    results: "27.25"
  },
  {
    words: "Mardi-gras , Mardi gra",
    results: "37.11.77"
  },
  {
    words: "Marteau , mato",
    results: "51.62"
  },
  {
    words: "Matelas , matla",
    results: "4.03"
  },
  {
    words: "Ma\u00efs en grain , Mayi grenn",
    results: "20.18.97"
  },
  {
    words: "Ma\u00efs moulu , mayi moulen",
    results: "27"
  },
  {
    words: "Melon , melon",
    results: "11.82.29.62"
  },
  {
    words: "Mer , lanm\u00e8",
    results: "7.18"
  },
  {
    words: "Messe , m\u00e8s",
    results: "92.93.05"
  },
  {
    words: "Meuble , m\u00e8b",
    results: "20"
  },
  {
    words: "Miroir , glas",
    results: "29.84.22"
  },
  {
    words: "Monseigneur , Monseye",
    results: "78.79"
  },
  {
    words: "Montagne , m\u00f2n",
    results: "12.5"
  },
  {
    words: "Montre , Mont, Watch",
    results: "25.90.21.58.90.08"
  },
  {
    words: "Mort , lanm\u00f2",
    results: "08.33.74"
  },
  {
    words: "Motocyclette , motosikl\u00e8t",
    results: "53.21.71.92"
  },
  {
    words: "Mouches , mouch",
    results: "45.25.09.24"
  },
  {
    words: "Mouchoir , mouchwa",
    results: "84"
  },
  {
    words: "Moustique , moustik",
    results: "35.96"
  },
  {
    words: "Mulet (charg\u00e9) , Mil\u00e8t (chaje)",
    results: "34.43"
  },
  {
    words: "Mur (en manger) , Mi (manje)",
    results: "44.67"
  },
  {
    words: "Muscade , Miskad",
    results: "10.45.28.65"
  },
  {
    words: "Musicien , mizisyen",
    results: "39"
  },
  {
    words: "M\u00e9canique , mekanik",
    results: "21.63"
  },
  {
    words: "Oeufs , ze",
    results: "60.00.13.16.75.87"
  },
  {
    words: "Oiseaux , zwazo",
    results: "47.60.27.02"
  },
  {
    words: "Ombrelle , parapli",
    results: "27.57"
  },
  {
    words: "Orages , loraj",
    results: "44.99.22.47"
  },
  {
    words: "Orange , zoranj",
    results: "44.02.40.13"
  },
  {
    words: "Outils , zouti",
    results: "29"
  },
  {
    words: "Paille , pay",
    results: "27.48.41.04"
  },
  {
    words: "Pain , pen",
    results: "60.33.58.50"
  },
  {
    words: "Palais , pal\u00e8",
    results: "53.09"
  },
  {
    words: "Panier , panyen",
    results: "19.69.89"
  },
  {
    words: "Pantalon , pantalon",
    results: "88.20.77"
  },
  {
    words: "Papier , papye",
    results: "79.07"
  },
  {
    words: "Papillon , papiyon",
    results: "02.94.12.35"
  },
  {
    words: "Parapluie , parapli",
    results: "80.57.21"
  },
  {
    words: "Parfum , pafen",
    results: "91.23"
  },
  {
    words: "Passeport , pasp\u00f2",
    results: "79.15"
  },
  {
    words: "patate , patat",
    results: "3.3"
  },
  {
    words: "Pat\u00e9 , Pat\u00e9",
    results: "89.02"
  },
  {
    words: "Piano , pyano",
    results: "98.99.29"
  },
  {
    words: "Pigeon , pijon",
    results: "24.15"
  },
  {
    words: "Piment , piman",
    results: "71"
  },
  {
    words: "Pintade , Gine zwazo",
    results: "33.13.73"
  },
  {
    words: "Pipe , Pip",
    results: "56.12.17"
  },
  {
    words: "Plaine , plenn",
    results: "72.21.26.43"
  },
  {
    words: "Planche , Planch",
    results: "81.88"
  },
  {
    words: "Planter , plante",
    results: "97"
  },
  {
    words: "Pluie , lapli",
    results: "11.2299.21"
  },
  {
    words: "Plume , plim",
    results: "07.22.33.18"
  },
  {
    words: "Pois , pwa",
    results: "87.19.90"
  },
  {
    words: "Poisson , pwason",
    results: "27.18.78"
  },
  {
    words: "Police , lapolis",
    results: "4.44"
  },
  {
    words: "Pomme , p\u00f2m",
    results: "28.59"
  },
  {
    words: "Pont , pon",
    results: "12.73"
  },
  {
    words: "Poule , manman poul",
    results: "23.70.37"
  },
  {
    words: "pretre , pr\u00e8t",
    results: "45.16"
  },
  {
    words: "Pr\u00e9sident , prezidan",
    results: "11.26.45.79"
  },
  {
    words: "Punaise , pun\u00e8z",
    results: "97.99.30"
  },
  {
    words: "Putain , jenn\u00e8s",
    results: "66.21"
  },
  {
    words: "Radio , radyo",
    results: "43.24"
  },
  {
    words: "Raisin , rezen",
    results: "19.90.42"
  },
  {
    words: "Rara , Rara",
    results: "79.97"
  },
  {
    words: "Rat , rat",
    results: "29.90.26"
  },
  {
    words: "Ravet , Ravet",
    results: "48.33.30"
  },
  {
    words: "Ravine , ravin",
    results: "1"
  },
  {
    words: "Reine , larenn",
    results: "56.31.82"
  },
  {
    words: "Requin , reken",
    results: "45"
  },
  {
    words: "Restaurant , restoran",
    results: "68.28.78"
  },
  {
    words: "Revolver , rev\u00f2lv\u00e8",
    results: "44.45.30"
  },
  {
    words: "Richesse , rich\u00e8s",
    results: "50.51"
  },
  {
    words: "Rideau , rido",
    results: "51.53.45"
  },
  {
    words: "Rigole , ravin",
    results: "56.85"
  },
  {
    words: "Rire , ri",
    results: "64.13"
  },
  {
    words: "Rivi\u00e8re Claire , Rivy\u00e8",
    results: "19.33"
  },
  {
    words: "Rivi\u00e8re Sale , Vann River",
    results: "07.09.23"
  },
  {
    words: "Robe , rad",
    results: "02.51.21.75"
  },
  {
    words: "Roche , w\u00f2ch",
    results: "25.96.21"
  },
  {
    words: "Roi , wa",
    results: "27.56.78"
  },
  {
    words: "Rose , woz",
    results: "32.35"
  },
  {
    words: "Rechaud , Recho",
    results: "13"
  },
  {
    words: "Sable , sab",
    results: "89.97"
  },
  {
    words: "Sac , sak",
    results: "33.78"
  },
  {
    words: "Sandale , sapat",
    results: "9.52"
  },
  {
    words: "Sang , san",
    results: "45.61.05"
  },
  {
    words: "Saucisse , sosis",
    results: "08.97.22"
  },
  {
    words: "Savon , savon",
    results: "76.34.15.92"
  },
  {
    words: "Sel , s\u00e8l",
    results: "09.16.18"
  },
  {
    words: "sirop , siwo",
    results: "82.9"
  },
  {
    words: "Soif , swaf dlo",
    results: "25.75.51"
  },
  {
    words: "Soleil , Sol\u00e8y",
    results: "61.25.33"
  },
  {
    words: "Soulier , soulye",
    results: "88.11.28"
  },
  {
    words: "Soupe , soup",
    results: "38.45.58"
  },
  {
    words: "Souris , sourit",
    results: "15.29.05.89"
  },
  {
    words: "Soutien , sip\u00f2",
    results: "61.06.43"
  },
  {
    words: "Statue , estati",
    results: "19.55.66"
  },
  {
    words: "Sucre , sik",
    results: "6"
  },
  {
    words: "Surette , Sir\u00e8t",
    results: "38.51.56"
  },
  {
    words: "Tabac , tabak",
    results: "21.32.43.51"
  },
  {
    words: "Table , tab",
    results: "37.04"
  },
  {
    words: "Tableau , tablo",
    results: "17.6"
  },
  {
    words: "Tambour , tanbou",
    results: "30.28"
  },
  {
    words: "Tapis , tapi",
    results: "17"
  },
  {
    words: "Telle , T\u00e8l",
    results: "26.11"
  },
  {
    words: "Terrain , t\u00e8",
    results: "65"
  },
  {
    words: "Th\u00e9atre , Teyat",
    results: "80.02"
  },
  {
    words: "Tole , T\u00f2l",
    results: "63"
  },
  {
    words: "Tomate , tomat",
    results: "74.45"
  },
  {
    words: "Tombe , kavo",
    results: "67.08"
  },
  {
    words: "Train , tren",
    results: "73.12.70"
  },
  {
    words: "Travaille ,  travay",
    results: "54.25.78"
  },
  {
    words: "Tribunal , tribinal",
    results: "35 37"
  },
  {
    words: "Trompette , twonp\u00e8t",
    results: "32"
  },
  {
    words: "Trou , twou",
    results: "07 09"
  },
  {
    words: "Tuyau , kawotchou",
    results: "22 05 34"
  },
  {
    words: "Telephone , Telef\u00f2n",
    results: "70"
  },
  {
    words: "Usine , faktori",
    results: "19 27"
  },
  {
    words: "Vent , van",
    results: "75 86 32"
  },
  {
    words: "Voleur , v\u00f2l\u00e8",
    results: "59 47 07"
  },
  {
    words: "Voyage , vwayaj",
    results: "44 61 92 16 08 21"
  },
  {
    words: "Lesly Center",
    results: "54"
  },
  {
    words: "Mangue, Mango, Mango",
    results: "350 50 05"
  },
  {
    words: "Mal\u00e8t, Bagages, Luggage",
    results: "373. 04. 86"
  },
  {
    words: "Masques, Mas, Mask",
    results: "65.05.06"
  },
  {
    words: "Medaille, Meday, Medal",
    results: "13. 93"
  },
  {
    words: "Mardi, Madi, Tuesday",
    results: "34. 04. 77"
  },
  {
    words: "Ma\u00efs, Mayi, Corn",
    results: "97. 20. 18. 29. 59. 76. 79"
  },
  {
    words: "Maladie, Maladi, Disease",
    results: "58. 22. 30. 38. 02. "
  },
  {
    words: "Mercredi, M\u00e8kredi, Wednesday",
    results: "45. 15. 88"
  },
  {
    words: "New York",
    results: "587. 87"
  },
  {
    words: "Paradis, Paradi, Paradise",
    results: "29. 72. 20. 73"
  },
  {
    words: "Naissance, Nesans, Birth",
    results: "09. 87"
  },
  {
    words: "Novembre, Novanm, November",
    results: "07. 12. 24"
  },
  {
    words: "Notaire, Not\u00e8, Notary",
    results: "952. 95. 02 962. 62"
  },
  {
    words: "Papa, Papa, Father",
    results: "29. 41. 28, 24, 09, 70, 35"
  },
  {
    words: "Pays Etranger, A letranje, Foreign country",
    results: "87. 00. 342. 42. 35"
  },
  {
    words: "Vetement, Rad, Clothing",
    results: "616. 28. 31"
  }
];
