import * as React from 'react';

import { RichText } from 'prismic-reactjs';
import { Link } from 'react-router-dom';
import background from 'assets/images/inner-page-bg.png';
import { useTranslations } from 'hooks/useTranslations';

import { translates } from './translate';
import { Loading } from 'components/Loading';
import { useFindByDocumentId } from 'hooks/useFindByDocumentId';

const Faq = () => {
  const { lang, translations } = useTranslations({ translates });
  const { result, loading } = useFindByDocumentId({
    type: 'faq',
  });
  if (loading || !result) {
    return <Loading />;
  }

  const {
    data: { title, description, faq_group },
  } = result;

  return (
    <>
      <section
        className='inner-page-banner has_bg_image'
        data-background={background}
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='inner-page-banner-area'>
                <h1 className='page-title'>FAQ</h1>
                <nav aria-label='breadcrumb' className='page-header-breadcrumb'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <Link to={`/${lang}/home`}>{translations?.home}</Link>
                    </li>
                    <li className='breadcrumb-item active'>FAQ</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='faq-section section-padding'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8'>
              <div className='section-header text-center'>
                <h2 className='section-title'>{RichText.asText(title)}</h2>
                <p>{RichText.asText(description)}</p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='faq-wrapper'>
                <div className='tab-content' id='myTabContent'>
                  <div
                    className='tab-pane fade active show'
                    id='results'
                    role='tabpanel'
                    aria-labelledby='results-tab'
                  >
                    <div
                      className='accordion sorteo-accordion'
                      id='accordionExample'
                    >
                      {faq_group?.map(({ title, description }, index) => (
                        <div className='card' key={RichText.asText(title)}>
                          <div className='card-header' id={'heading' + index}>
                            <h2 className='mb-0'>
                              <button
                                className='btn btn-link'
                                type='button'
                                data-toggle='collapse'
                                data-target={'#collapse' + index}
                                aria-expanded='true'
                                aria-controls={'collapse' + index}
                              >
                                <i className='fa fa-question'></i>
                                {RichText.asText(title)}
                              </button>
                            </h2>
                          </div>
                          <div
                            id={'collapse' + index}
                            className='collapse show'
                            aria-labelledby={'collapse' + index}
                            data-parent='#accordionExample'
                          >
                            <div className='card-body'>
                              <RichText render={description} />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { Faq };
