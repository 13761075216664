import * as React from 'react';
import { useInternationalRoute } from '../useInternationalRoute';

import { translates as t } from './translates';

const useTranslations = ({ translates = {} } = {}) => {
  const { lang } = useInternationalRoute();

  const translations = React.useMemo(() => {
    const tran = { ...translates, ...t };
    return Object.keys(tran).reduce(
      (acc, key) => ({
        ...acc,
        [key]: tran[key]?.[lang],
      }),
      {}
    );
  }, [translates, lang]);

  const language = React.useMemo(() => {
    switch (lang) {
      case 'en':
        return 'en-US';
      default:
        return 'fr-FR';
    }
  }, [lang]);

  return {
    lang,
    fullLanguage: language,
    translations,
  };
};

export { useTranslations };
