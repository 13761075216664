const translates = {
  hours: {
    en: "Hours",
    ht: "Èdtan",
    fr: "Heures"
  },
  minutes: {
    en: "Minutes",
    ht: "Minit",
    fr: "Minutes"
  },
  seconds: {
    en: "Seconds",
    ht: "Segond",
    fr: "Secondes"
  }
};

export { translates };
