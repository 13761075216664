import * as React from 'react';

import { NavLink, useLocation } from 'react-router-dom';

import logo from 'assets/img/logo-bolet.png';
import { makeStyles } from '@material-ui/core';
import { useInternationalRoute } from 'hooks/useInternationalRoute';
import { useTranslations } from 'hooks/useTranslations';

import { translates } from './translate';

const useStyles = makeStyles({
  logo: {
    height: 75,
  },
});

const HeaderBar = () => {
  const { pathname } = useLocation();
  const classes = useStyles();
  const { getRoute } = useInternationalRoute();
  const { translations } = useTranslations({ translates });

  React.useEffect(() => {
    if (!pathname) return;
    window.$('.navbar-collapse').removeClass('show');
  }, [pathname]);

  return (
    <div className='header-bottom'>
      <div className='container'>
        <nav className='navbar navbar-expand-xl'>
          <a className='site-logo site-title' href='home-one.html'>
            <img src={logo} alt='site-logo' className={classes.logo} />
          </a>
          <button
            className='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='menu-toggle'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul className='navbar-nav main-menu ml-auto'>
              <li>
                <NavLink className='nav-item' to={getRoute('/home')}>
                  {translations?.home}
                </NavLink>
              </li>
              <li>
                <NavLink to={getRoute('/about-us')}>
                  {translations?.aboutUs}
                </NavLink>
              </li>
              <li>
                <NavLink to={getRoute('/results')}>
                  {translations?.results}
                </NavLink>
              </li>
              <li>
                <NavLink to={getRoute('/history')}>
                  {translations?.history}
                </NavLink>
              </li>
              <li>
                <NavLink to={getRoute('/tchala')}>
                  {translations?.prediction}
                </NavLink>
              </li>
              <li>
                <NavLink to={getRoute('/faq')}>{translations?.faq}</NavLink>
              </li>
              <li>
                <NavLink to={getRoute('/contact-us')}>
                  {translations?.contactUs}
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export { HeaderBar };
