import * as React from 'react';

import { Link } from 'react-router-dom';
import background from 'assets/images/inner-page-bg.png';
import background2 from 'assets/images/inner-page-banner.jpg';
import { useFindByDocumentId } from 'hooks/useFindByDocumentId';
import { Loading } from 'components/Loading';
import { useTranslations } from 'hooks/useTranslations';
import { RichText } from 'prismic-reactjs';

const AboutUs = () => {
  const { loading, result } = useFindByDocumentId({ type: 'about' });
  const { translations, lang } = useTranslations();
  if (loading || !result) {
    return <Loading />;
  }

  const { data } = result;

  return (
    <>
      <section
        className='inner-page-banner has_bg_image'
        data-background={background}
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='inner-page-banner-area'>
                <h1 className='page-title'> {translations.aboutUs}</h1>
                <nav aria-label='breadcrumb' className='page-header-breadcrumb'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <Link to={`/${lang}/home`}>{translations.home}</Link>
                    </li>
                    <li className='breadcrumb-item active'>
                      {translations.aboutUs}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {data?.about_section?.map(({ title, description }, index) => {
        return (
          <section
            key={index}
            data-background={background2}
            style={{ backgroundImage: `url(${background2})` }}
            className='choose-us-section section-padding border-top border-bottom has_bg_image'
          >
            <div className='container'>
              <div className='row justify-content-center'>
                <div className='col-lg-9'>
                  <div className='section-header text-center'>
                    <h2 className='section-title'>{RichText.asText(title)}</h2>
                    <p>{RichText.asText(description)}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      })}
    </>
  );
};

export { AboutUs };
