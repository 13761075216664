import * as React from 'react';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory, useLocation, useParams } from 'react-router-dom';

const SupportBar = () => {
  const { lang } = useParams();
  const location = useLocation();
  const history = useHistory();

  const onLanguageChange = ({ target }) => {
    history.push({
      pathname: `/${target?.value}${location.pathname?.substr(3)}`,
    });
  };

  return (
    <div className='header-top'>
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-md-8 col-xs-12'>
            <div className='header-top-left d-flex'>
              <div className='support-part' style={{ paddingRight: 16 }}>
                {/*
                <a href='tel:+88016558888454'>
                  <i className='fa fa-headphones'></i>Support
                </a>*/}
              </div>
              <div className='email-part' style={{ paddingRight: 8 }}>
                {/*
                <a href='mailto:info@sorteo.com'>
                  <i className='fa fa-envelope'></i>hello@boletideal.com
                </a>
                */}
              </div>
            </div>
          </div>
          <div className='col-md-4 col-xs-12'>
            <div
              className='support-part'
              style={{
                paddingRight: 16,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <i
                className='fa fa-globe'
                style={{
                  paddingRight: 8,
                  color: 'var(--main-color)',
                }}
              ></i>
              <Select
                variant='standard'
                value={lang}
                onChange={onLanguageChange}
              >
                <MenuItem value='fr'>Français</MenuItem>
                <MenuItem value='ht'>Kreyól</MenuItem>
                <MenuItem value='en'>English</MenuItem>
              </Select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SupportBar };
