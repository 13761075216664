import * as React from 'react';

import { Link } from 'react-router-dom';
// import { Pagination } from "components/Pagination";
import { useTranslations } from 'hooks/useTranslations';
import Pagination from 'antd/lib/pagination';
import 'antd/lib/pagination/style/css';
import background from 'assets/images/inner-page-bg.png';

import { data } from './config';
import { translates } from './translate';
import { paginate } from 'utils/array';
import { useFilter } from 'hooks/useFilter';

const Prediction = () => {
  const [page, setPage] = React.useState(1);
  const { data: array, onChange } = useFilter({ array: data });
  const { lang, translations } = useTranslations({ translates });

  const totalItems = array.length;
  const items = paginate(array)({ page, pageSize: 10 });

  React.useEffect(() => {
    if (!page) return;
    document.getElementById('table')?.scrollIntoView();
  }, [page]);

  return (
    <>
      <section
        className='inner-page-banner has_bg_image'
        data-background={background}
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='inner-page-banner-area'>
                <h1 className='page-title'>{translations?.tchala}</h1>
                <nav aria-label='breadcrumb' className='page-header-breadcrumb'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <Link to={`/${lang}/home`}>{translations?.home}</Link>
                    </li>
                    <li className='breadcrumb-item active'>
                      {translations?.tchala}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='cart-section section-padding' id='table'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='cart-area d-flex'>
                <div className='col-lg-12'>
                  <div className='cart-table'>
                    <table>
                      <thead>
                        <tr>
                          <th>{translations?.keywords}</th>
                          <th>{translations?.results}</th>
                        </tr>
                        <tr>
                          <th className='comment-form'>
                            <input
                              placeholder={translations?.searchKeywords}
                              name='words'
                              onChange={onChange}
                            />
                          </th>
                          <th className='comment-form'>
                            <input
                              placeholder={translations?.searchResults}
                              name='results'
                              onChange={onChange}
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {items?.map(({ words, results }, index) => (
                          <tr key={words + index}>
                            <td>{words}</td>
                            <td>{results}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    pageSize={10}
                    style={{ marginTop: 10, width: '100%' }}
                    size='small'
                    showSizeChanger={false}
                    total={totalItems}
                    current={page}
                    onChange={setPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { Prediction };
