import React from 'react'

import styled from 'styled-components'
import { TransverseLoading } from 'react-loadingg'
import CaretLeftOutlined from '@ant-design/icons/CaretLeftFilled'
import { Roulette } from 'components/Roulette'
import getBallotSize from 'utils/getBallotSize'

const ResultWrapper = styled.div`
  position: relative;
  padding-right: 30px;

  & > span > svg {
    position: absolute;
    right: 0;
    top: calc(50% - 175px / 5);

    & > path {
      color: #ba000d;
    }
  }
`

const Lottery = ({ index, draw, onComplete, timestamp }) => {
  const drumsAudio = React.useRef(new Audio('/assets/audio/drum-roll.mp3'))
  const golAudio = React.useRef(new Audio('/assets/audio/goal.mp3'))
  const semaphore = React.useRef(null)
  const [result, setResult] = React.useState(null)
  const ballotSize = getBallotSize()

  const { draw_id } = draw

  const urlDrawResult = 'https://webservices-internal.prod.idealoto.cc/v1/current/draw/result/'
  // 'https://internal.falseideal.com/v1/current/draw/result/'
  const serviceURL = `${urlDrawResult}${draw_id}`

  const getData = React.useCallback(async () => {
    if (semaphore.current) return
    try {
      semaphore.current = true
      const data = await fetch(
        serviceURL,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      const response = await data.json()
      setResult(response)
    } catch (e) {
      window.setTimeout(() => {
        getData()
      }, 10000)
    } finally {
      semaphore.current = false
    }
  }, [serviceURL])

  React.useEffect(() => {
    setTimeout(() => {
      getData()
    }, 1500)
  }, [getData])

  React.useEffect(() => {
    if (!result) {
      return
    }
    let interval
    drumsAudio.current.currentTime = 3
    drumsAudio.current.play()
    // drumsAudio.current.muted = true;
    // drumsAudio.current.volume= 1;
    drumsAudio.current.addEventListener('play', () => {
      interval = window.setInterval(() => {
        drumsAudio.current.currentTime = 3
      }, 2000)
    })
    drumsAudio.current.addEventListener('pause', () => {
      window.clearInterval(interval)
    })
    // setTimeout(() => {
    // drumsAudio.current.play();
    // },1000)
  }, [result])

  // React.useEffect(() => {
  //   if (!result) return;
  //   window.setTimeout(() => {
  //     if (!onComplete) return;
  //     onComplete();
  //   }, 37);
  // }, [result, onComplete]);

  const getDurationByBallot = React.useCallback((ballot = 1) => {
    // const currentDate = zonedTimeToUtc(timestamp);
    // const drawDate = zonedTimeToUtc(draw_date);
    // const spanDate = addDate(drawDate, {
    //   seconds: Math.pow(2, 3 + ballot),
    // });

    // const diff = differenceInMillisecondsfrom(spanDate, currentDate);
    // return diff > 0 ? diff / 1000 : 0;
    return Math.pow(2, 3 + ballot)
  }, [])

  const getBallot = ({ index, length = 2 }) => {
    if (!result || !result?.draw_ballots) return null
    const { draw_ballots } = result
    const value = Number.parseInt(
      `${draw_ballots?.replace(/-/g, '')}`.substr(index, length),
      10
    )

    switch (value) {
      case 0:
        return 99
      default:
        return value - 1
    }
  }

  return (
    <section
      className="lottery-timer-section"
      style={{
        paddingTop: 16,
        paddingBottom: 16,
        backgroundColor: index % 2 === 0 ? '#f8fcff' : 'white',
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            {!result && <TransverseLoading style={{ height: 200 }} />}
            {result &&
              Array(4)
                .fill(null)
                .map((_, index) => (
                  <ResultWrapper key={index}>
                    <Roulette
                      duration={getDurationByBallot(index)}
                      length={index === 0 ? 10 : 100}
                      pad={index === 0 ? 1 : 2}
                      speed={15}
                      onStop={() => {
                        function onEnded() {
                          if (index < 3) {
                            drumsAudio.current.currentTime = 3
                            drumsAudio.current.play()
                          }
                          golAudio.current.removeEventListener('ended', onEnded)
                        }
                        drumsAudio.current.pause()
                        golAudio.current.addEventListener('ended', onEnded)
                        golAudio.current.play()
                        if (index === 3 && onComplete) {
                          onComplete(true)
                        }
                      }}
                      result={getBallot({
                        index: index > 0 ? index * 2 - 1 : 0,
                        length: index > 0 ? 2 : 1,
                      })}
                    />
                    <CaretLeftOutlined style={{ fontSize: ballotSize + 10 }} />
                  </ResultWrapper>
                ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export { Lottery }
