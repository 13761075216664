const translates = {
  results: {
    en: 'Results',
    fr: 'Résultats',
    ht: 'Rezilta',
  },
  drawStartAt: {
    en: 'The draw will start in',
    fr: 'Le tirage au sort commencera à',
    ht: 'Tiraj la ap kòmanse nan',
  },
  latestResults: {
    en: 'Latest Lottery Results',
    ht: 'Dènye Rezilta Lotri',
    fr: 'Derniers résultats de la loterie',
  },
  lotteryWinningNumber: {
    en: 'Lottery winning numbers',
    fr: 'Lottery winning numbers',
    ht: 'Lotri genyen nimewo',
  },
}

export { translates }
