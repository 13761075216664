const translates = {
  history: {
    en: 'History',
    fr: 'Historique',
    ht: 'Istorik',
  },
  startDate: {
    en: 'Start date',
    fr: 'Date de début',
    ht: 'Dat kòmansman',
  },
  endDate: {
    en: 'End date',
    fr: 'Date de fin',
    ht: 'Dat fen',
  },
  results: {
    en: 'Results',
    fr: 'Résultats',
    ht: 'Rezilta',
  },
  drawing: {
    en: 'Drawing',
    fr: 'Tirage',
    ht: 'Tiraj',
  },
  winningNumber: {
    en: 'Winning Numbers',
    fr: 'Numéro Gagnants',
    ht: 'Boul ki Sóti',
  },
  date: {
    en: 'Date',
    fr: 'Date',
    ht: 'Dat',
  },
  searchDrawing: {
    en: 'Search Drawing',
    fr: 'Rechercher Tirage',
    ht: 'Rechèch Tiraj',
  },
  searchWinningNumber: {
    en: 'Search Winning Numbers',
    fr: 'Rechercher Numéro Gagnants',
    ht: 'Rechèch Boul ki Sóti',
  },
  searchDate: {
    en: 'Search Date',
    fr: 'Rechercher Date',
    ht: 'Rechèch Dat',
  },
  send: {
    en: 'Search',
    fr: 'Recherchez',
    ht: 'Rechèch',
  },
};

export { translates };
