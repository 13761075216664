import Prismic from 'prismic-javascript';

const {
  REACT_APP_PRISMIC_ENDPOINT,
  REACT_APP_PRISMIC_ACCESS_TOKEN,
} = process.env;

const prismic = Prismic.client(REACT_APP_PRISMIC_ENDPOINT, {
  accessToken: REACT_APP_PRISMIC_ACCESS_TOKEN,
});

export { prismic };
