import * as React from 'react';

import { ThemeProvider } from '@material-ui/core/styles';
import { boletTheme } from '../../utils/theme';

const BoletThemeProvider = ({ children }) => {
  return <ThemeProvider theme={boletTheme}>{children}</ThemeProvider>;
};

export { BoletThemeProvider };
