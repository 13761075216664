import * as React from 'react';

// import { encode } from "querystring";
import { Link } from 'react-router-dom';
import background from 'assets/images/inner-page-bg.png';
import Pagination from 'antd/lib/pagination';
import 'antd/lib/pagination/style/css';
import { useTranslations } from 'hooks/useTranslations';
import { translates } from './translate';
import { useFilter } from 'hooks/useFilter';
import { paginate } from 'utils/array';

const History = () => {
  const [page, setPage] = React.useState(1);
  const loaded = React.useRef();
  const [data, setData] = React.useState([]);
  const { lang, translations } = useTranslations({ translates });
  const { data: array, onChange } = useFilter({ array: data });

  const totalItems = array.length;
  const items = paginate(array)({ page });

  const submit = React.useCallback(async () => {
    try {
      const data = await fetch(
        `https://webservices-internal.prod.idealoto.cc/v1/draws/results`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const response = await data.json();
      setData(response);
    } catch (e) {
      setData([]);
    }
  }, []);

  React.useEffect(() => {
    if (loaded.current) return;
    submit();
    loaded.current = true;
  }, [submit]);

  React.useEffect(() => {
    if (!page) return;
    document.getElementById('table')?.scrollIntoView();
  }, [page]);

  return (
    <>
      <section
        className='inner-page-banner has_bg_image'
        data-background={background}
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='inner-page-banner-area'>
                <h1 className='page-title'>{translations?.history}</h1>
                <nav aria-label='breadcrumb' className='page-header-breadcrumb'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <Link to={`/${lang}/home`}>{translations?.home}</Link>
                    </li>
                    <li className='breadcrumb-item active'>
                      {translations?.history}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='cart-section section-padding' id='table'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='cart-area d-flex'>
                <div className='col-lg-12'>
                  <div className='cart-table'>
                    <table>
                      <thead>
                        <tr>
                          <th>{translations?.drawing}</th>
                          <th>{translations?.winningNumber}</th>
                          <th>{translations?.date}</th>
                        </tr>
                        <tr>
                          <th className='comment-form'>
                            <input
                              placeholder={translations?.searchDrawing}
                              name='draw_name'
                              onChange={onChange}
                            />
                          </th>
                          <th className='comment-form'>
                            <input
                              placeholder={translations?.searchWinningNumber}
                              name='draw_ballots'
                              onChange={onChange}
                            />
                          </th>
                          <th className='comment-form'>
                            <input
                              placeholder={translations?.searchDate}
                              name='draw_date'
                              onChange={onChange}
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {items?.map(
                          (
                            { draw_ballots, draw_id, draw_name, draw_date },
                            index
                          ) => (
                            <tr key={draw_ballots + index}>
                              <td>{draw_name}</td>
                              <td>{draw_ballots}</td>
                              <td>{draw_date}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    pageSize={10}
                    style={{ marginTop: 10, width: '100%' }}
                    size='small'
                    showSizeChanger={false}
                    total={totalItems}
                    current={page}
                    onChange={setPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { History };
