import * as React from "react";

import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
  useParams
} from "react-router-dom";

const defaultLang =
  window.localStorage.getItem("lang") ||
  window.navigator?.language?.split("-")[0] ||
  "fr";

const LanguageContext = React.createContext({ lang: defaultLang });

const LanguageRoute = ({ children }) => {
  const { lang = defaultLang } = useParams();

  React.useEffect(() => {
    window.localStorage.setItem("lang", lang);
  }, [lang]);

  if (!["fr", "en", "ht"].includes(lang)) {
    return <Redirect to={defaultLang} />;
  }

  return (
    <LanguageContext.Provider value={{ lang }}>
      {children}
    </LanguageContext.Provider>
  );
};

const InternationalizationRoute = ({ children }) => {
  return (
    <Router>
      <Switch>
        <Route path="/:lang">
          <LanguageRoute>{children}</LanguageRoute>
        </Route>
        <Route path="*">
          <Redirect to={`/${defaultLang}`} />
        </Route>
      </Switch>
    </Router>
  );
};

export { InternationalizationRoute, LanguageContext };
