import * as React from 'react';

import { Link } from 'react-router-dom';
import background from 'assets/images/bg-four.jpg';
import logo from 'assets/img/logo-bolet.png';
import { useTranslations } from 'hooks/useTranslations';
import { translates } from './translate';
import { useInternationalRoute } from 'hooks/useInternationalRoute';

const Footer = () => {
  const { getRoute } = useInternationalRoute();
  const { translations, lang } = useTranslations({ translates });
  return (
    <footer className='footer-section'>
      <div
        className='footer-top border-top border-bottom has_bg_image'
        data-background={background}
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className='footer-top-first'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6 col-md-5 col-sm-4 text-center text-sm-left'>
                <a href='home-one.html' className='site-logo'>
                  <img src={logo} alt='logo' />
                </a>
              </div>
              <div className='col-lg-6 col-md-7 col-sm-8'>
                <div className='number-count-part d-flex'></div>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-top-seond'>
          {' '}
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-xl-2 col-lg-3 col-md-3 col-sm-6'>
                <div className='footer-widget widget-about'>
                  <h3 className='widget-title'>
                    {translations?.aboutBoletIdeal}
                  </h3>
                  <ul className='footer-list-menu'>
                    <li>
                      <Link to={getRoute('/about-us')}>
                        {translations?.aboutUs}
                      </Link>
                    </li>
                    <li>
                      <Link to={getRoute('/contact-us')}>
                        {translations?.contactUs}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-xl-2 col-lg-3 col-md-3 col-sm-6'>
                <div className='footer-widget widget-links'>
                  <h3 className='widget-title'>{translations?.quickLinks}</h3>
                  <ul className='footer-list-menu'>
                    <li>
                      <Link to={getRoute('/results')}>
                        {translations?.results}
                      </Link>
                    </li>
                    <li>
                      <Link to={getRoute('/history')}>
                        {translations?.history}
                      </Link>
                    </li>
                    <li>
                      <Link to={getRoute('/tchala')}>
                        {translations?.prediction}
                      </Link>
                    </li>
                    <li>
                      <Link to={getRoute('/faq')}>{translations?.faq}</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                <div className='footer-widget widget-subscribe'>
                  {/* <h3 className="widget-title"> */}
                  {/*   {translations?.emailNewsletters} */}
                  {/* </h3> */}
                  <div className='subscribe-part'>
                    {/* <p>{translations?.newsletters} </p> */}
                    {/* <form className="subscribe-form"> */}
                    {/*   <input */}
                    {/*     type="email" */}
                    {/*     name="subs_email" */}
                    {/*     id="subs_email" */}
                    {/*     placeholder="Email" */}
                    {/*   /> */}
                    {/*   <input type="submit" value={translations?.subscribe} /> */}
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='footer-bottom'>
        <div className='container'>
          <div className='row justify-content-between align-items-center'>
            <div className='col-lg-6 col-sm-7'>
              <div className='copy-right-text'>
                <p>
                  © 2019-2020 <Link to={`/${lang}/home`}>Bolet ideal</Link> -
                  copyright by Bolet Ideal powered by idealtel
                </p>
              </div>
            </div>
            <div className='col-lg-6 col-sm-5'>
              <ul className='footer-social-links d-flex justify-content-end'>
                <li
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <a href='tel:++50936464040' title='+88016558888454'>
                    <i className='fa fa-phone'></i>
                  </a>
                  <p style={{ marginBottom: 0, paddingLeft: 4 }}>
                    <p
                      style={{
                        marginBottom: 0,
                        fontSize: '16px',
                        lineHeight: 1.2,
                      }}
                    >
                      <a href='tel:++50936464040'>+509 36 46 4040</a>
                    </p>
                    <p
                      style={{
                        marginBottom: 0,
                        fontSize: '16px',
                        lineHeight: 1.2,
                      }}
                    >
                      <a href='tel:+50933334040'>+509 33 33 4040</a>
                      {' / '}
                      <a href='tel:+50933324040'>33 32 4040</a>
                    </p>
                  </p>
                </li>
                <li>
                  <a
                    rel='noreferrer'
                    href='https://www.facebook.com/bolet.ideal.3'
                    target='_blank'
                  >
                    <i className='fa fa-facebook'></i>
                  </a>
                </li>
                <li>
                  <a
                    rel='noreferrer'
                    href='https://www.instagram.com/boletideal/'
                    target='_blank'
                  >
                    <i className='fa fa-instagram'></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export { Footer };
