import { InternationalizationRoute } from "components/InternationalizationRoute";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BoletThemeProvider } from "./components/ThemeProvider";
import reportWebVitals from "./reportWebVitals";
// import 'antd/dist/antd.css'
import { ConfigProvider } from "antd";

ReactDOM.render(
  <BoletThemeProvider>
    <InternationalizationRoute>
      <ConfigProvider >
        <App />
      </ConfigProvider>
    </InternationalizationRoute>
  </BoletThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.info);
