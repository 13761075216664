import * as React from "react";

const ScrollToTop = () => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="scroll-to-top" onClick={handleClick}>
      <span className="scroll-icon">
        <i className="fa fa-angle-up"></i>
      </span>
    </div>
  );
};

export { ScrollToTop };
