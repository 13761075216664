import * as React from "react";

import { RichText } from "prismic-reactjs";

const AboutContent = ({
  data: {
    home_page_informative_title,
    home_page_informative_subtitle,
    home_page_informative_group,
    ...extra
  }
}) => {

  return (
    <section className="lottery-timer-section section-padding" id="about">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5">
            <div className="section-header text-center">
              <h2 className="section-title">
                {RichText.asText(home_page_informative_title)}
              </h2>
              {home_page_informative_subtitle?.length > 0 && (
                <RichText render={home_page_informative_subtitle} />
              )}
            </div>
          </div>
        </div>
        <div
          className="row m-bottom-not-30"
          style={{ justifyContent: "center" }}
        >
          {home_page_informative_group?.map(
            (
              {
                home_page_informative_group_image,
                home_page_informative_group_title,
                home_page_informative_group_subtitle
              },
              index
            ) => (
              <div
                className="col-lg-6 col-md-12"
                key={index}
                style={{ height: "100%" }}
              >
                <div className="affiliate-item text-center">
                  <div className="icon">
                    <div className="icon-inner" style={{fontSize: 38}}>
                      {RichText.asText(home_page_informative_group_title)[0]}
                      {/* <img */}
                      {/*   src={home_page_informative_group_image?.url} */}
                      {/*   alt={home_page_informative_group_image?.al} */}
                      {/* /> */}
                    </div>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      {RichText.asText(home_page_informative_group_title)}
                    </h4>
                    <RichText render={home_page_informative_group_subtitle} />
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export { AboutContent };
