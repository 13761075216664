import React from 'react'

import { zonedTimeToUtc } from 'date-fns-tz'
import isAfter from 'date-fns/isAfter'
import { CommingDraw } from './CommingDraw'
import { Lottery } from './Lottery'
import { useTranslations } from 'hooks/useTranslations'
import { translates } from './translates'

const Draw = ({ draw, index, onComplete, timestamp }) => {
  const { draw_name, draw_date } = draw
  const { translations } = useTranslations({ translates })
  const [isComplete, setComplete] = React.useState(false)
  const [isLotteryComplete, setLotteryComplete] = React.useState(false)

  const showCommingDraw =
    isAfter(zonedTimeToUtc(timestamp), zonedTimeToUtc(draw_date)) || isComplete

  React.useEffect(() => {
    if (isLotteryComplete) {
      const interval = setTimeout(() => {
        setComplete(false)
        setLotteryComplete(false)

        onComplete()
      }, 50000)
      return () => clearTimeout(interval)
    }
  }, [isLotteryComplete, onComplete])

  return (
    <section>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="faq-wrapper">
              <div
                className="tab-content"
                style={{ marginTop: 0 }}
                id="myTabContent"
              >
                <div
                  className="tab-pane fade active show"
                  id="results"
                  role="tabpanel"
                  aria-labelledby="results-tab"
                >
                  <div className="accordion sorteo-accordion">
                    <div
                      className="section-header text-center"
                      style={{ paddingBottom: 0 }}
                    >
                      <div className="card" key={draw_name}>
                        <div className="card-header" id={'heading' + index}>
                          <h2
                            className="section-title"
                            style={{ fontSize: 6, marginTop: 2 }}
                          >
                            <button
                              className="btn btn-link"
                              type="button"
                              data-toggle="collapse"
                              data-target={'#collapse' + index}
                              aria-expanded="true"
                              aria-controls={'collapse' + index}
                            >
                              <i className="fa fa-star"></i>
                              {draw_name}
                            </button>
                          </h2>
                        </div>
                        <div
                          id={'collapse' + index}
                          className="collapse"
                          aria-labelledby={'collapse' + index}
                          data-parent="#accordionGroup"
                        >
                          <div className="card-body" style={{ padding: 0 }}>
                            {showCommingDraw ? (
                              <Lottery
                                draw={draw}
                                index={index}
                                onComplete={setLotteryComplete}
                                timestamp={timestamp}
                              />
                            ) : (
                              <>
                                <p style={{ marginBottom: 16, marginTop: 0 }}>
                                  {translations?.drawStartAt}
                                </p>
                                <CommingDraw
                                  draw={draw}
                                  index={index}
                                  onComplete={setComplete}
                                  timestamp={timestamp}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export { Draw }
