import * as React from "react";

import background from "assets/images/bg-three.jpg";
import { useTranslations } from "hooks/useTranslations";
import { translates } from "./translates";
import { useRouteMatch } from "react-router-dom";

const ContactContent = () => {
  const { path } = useRouteMatch();
  const [form, setState] = React.useState({});
  const { translations } = useTranslations({ translates });

  React.useEffect(() => {
    if (!path.includes("contact-us")) return;
    setTimeout(() => {
      const contact = document.getElementById("contact");
      window.scrollTo({
        behavior: "smooth",
        top: contact?.offsetTop - 160
      });
    }, 200);
  }, [path]);

  const submit = async e => {
    e.preventDefault();
    const getReason = () => {
      switch (form.reason_id) {
        case "1":
          return translations?.becomeADistributor;
        case "2":
          return translations?.reportAProblem;
        default:
          return translations?.other;
      }
    };
    try {
      await fetch(
        `https://webservices-internal.prod.idealoto.cc/v1/internal/email/notify`,
        {
          method: "POST",
          body: JSON.stringify({
            ...form,
            reason_id: parseInt(form.reason_id, 10),
            reason: getReason()
          }),
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      setState({});
      window.alert(translations?.sentMessage);
    } catch (e) {}
  };

  return (
    <section
      id="contact"
      className="contact-section border-top overflow-hidden has_bg_image"
      data-background={background}
      style={{ backgroundImage: `url(${background})`, paddingBottom: 40 }}
    >
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-8">
            <div className="section-header text-center">
              <h2 className="section-title text-uppercase">
                {translations?.contactUs}
              </h2>
              <p>{translations?.informationContact}</p>
            </div>
            <div className="contact-form-area">
              <form
                className="contact-form"
                onChange={({ target: { name, value } }) => {
                  setState(state => ({ ...state, [name]: value }));
                }}
                onSubmit={submit}
              >
                <div className="form-grp">
                  <input
                    autoComplete="off"
                    type="text"
                    value={form.fullname ?? ""}
                    name="fullname"
                    id="fullname"
                    placeholder={translations?.completeName}
                    required
                  />
                </div>
                <div className="form-grp">
                  <input
                    autoComplete="off"
                    type="email"
                    name="email"
                    id="email"
                    value={form.email ?? ""}
                    placeholder={translations?.email}
                    required
                  />
                </div>
                <div className="form-grp">
                  <input
                    autoComplete="off"
                    type="tel"
                    name="phone"
                    id="phone"
                    value={form.phone ?? ""}
                    placeholder={translations?.phone}
                    required
                  />
                </div>
                <div className="form-grp">
                  <select
                    placeholder={translations?.reason}
                    name="reason_id"
                    id="reason_id"
                    value={form.reason_id ?? ""}
                    autoComplete="off"
                    required="required"
                    defaultValue=""
                  >
                    <option disabled value="">
                      {translations?.reason}
                    </option>
                    <option value={1}>
                      {translations?.becomeADistributor}
                    </option>
                    <option value={2}>{translations?.reportAProblem}</option>
                    <option value={3}>{translations?.other}</option>
                  </select>
                </div>
                <div className="form-grp">
                  <textarea
                    name="message"
                    autoComplete="off"
                    id="message"
                    value={form.message ?? ""}
                    placeholder={translations?.message}
                  ></textarea>
                </div>
                <div className="form-grp">
                  <input
                    className="submit-btn"
                    type="submit"
                    value={translations?.send}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { ContactContent };
