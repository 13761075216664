import * as React from "react";

const useFilter = ({ array = [] }) => {
  const timeout = React.useRef();
  const [data, setData] = React.useState(array);
  const [filter, setFilter] = React.useState({});

  const handleChange = ({ target: { name, value } }) => {
    setFilter(data => ({ ...data, [name]: value }));
  };

  React.useEffect(() => {
    window.clearTimeout(timeout.current);
    timeout.current = window.setTimeout(() => {
      const finalData = Object.keys(filter).reduce((acc = [], key) => {
        const value = filter[key];
        if (!value) return acc;
        return acc.filter(item =>
          `${item[key]}`.toUpperCase().includes(value.toUpperCase())
        );
      }, array);
      setData(finalData);
    }, 500);
  }, [filter, array]);

  return {
    data,
    onChange: handleChange
  };
};

export { useFilter };
