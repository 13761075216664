import * as React from 'react'
import { Ballot } from 'components/Ballot'
import getBallotSize from 'utils/getBallotSize'

const Roulette = ({
  speed = 5,
  duration = 1000,
  length = 10,
  pad = 2,
  result,
  onStart,
  onSlowDown,
  onStop,
}) => {
  const id = React.useRef(btoa(`${Math.random() * 1000000}`).substr(0, 9))
  const timeout = React.useRef()
  const size = getBallotSize()
  const ref = React.useRef(null)
  const options = React.useRef({
    speed,
    duration,
    stopImageNumber: result,
    startCallback: onStart,
    slowDownCallback: onSlowDown,
    stopCallback: onStop,
  })

  React.useEffect(() => {
    if (ref.current || duration === 0) return

    const rouletter = window.$(`#${id.current}`)

    ref.current = rouletter.roulette(options.current)

    timeout.current = setTimeout(() => {
      ref.current.roulette('start')
    }, 100)

    return () => {
      window.clearTimeout(timeout.current)
    }
  }, [duration])

  if (duration === 0) {
    return (
      <div className="roulette-inner">
        <Ballot size={size} value={`${result}`.padStart(pad, '0')} />
        <Ballot size={size} value={`${result + 1}`.padStart(pad, '0')} />
        <Ballot size={size} value={`${result + 2}`.padStart(pad, '0')} />
      </div>
    )
  }

  return (
    <div
      id={id.current}
      style={{
        display: 'none',
        overflow: 'hidden',
      }}
    >
      {Array(length)
        .fill(null)
        .map((_, index) => {
          return (
            <Ballot
              size={size}
              key={index}
              value={`${index}`.padStart(pad, '0')}
            />
          )
        })}
    </div>
  )
}

export { Roulette }
