const translates = {
  contactUs: {
    en: "Contact us",
    ht: "Kontakte nou",
    fr: "Contactez-nous"
  },
  informationContact: {
    en: "Information contact",
    fr: "Informations de contact",
    ht: "Enfòmasyon kontak"
  },
  completeName: {
    en: "Complete name",
    fr: "Nom complet",
    ht: "Non konplé"
  },
  phone: {
    en: "Phone",
    ht: "Telefòn",
    fr: "Téléphone"
  },
  email: {
    en: "E-mail",
    fr: "E-mail",
    ht: "E-mail"
  },
  reason: {
    en: "Reason",
    fr: "Motif",
    ht: "Motif"
  },
  joinNetwork: {
    en: "Join network",
    fr: "Rejoindre le résau",
    ht: "Antre nan rezo a"
  },
  chargeAPrice: {
    en: "Charge a price",
    fr: "Réclamer un paiement",
    ht: "Reklamer yom pèman"
  },
  somethingWentWrong: {
    en: "Something went wrong",
    fr: "Signaler un problème",
    ht: "Siyale yom pwoblèm"
  },
  reportAProblem: {
    en: "Report a problem",
    fr: "Rapporter un probleme",
    ht: "Rapòte yon pwoblèm"
  },
  other: {
    en: "Other",
    fr: "Autre",
    ht: "Lòt"
  },
  becomeADistributor: {
    en: "Become a Distributor",
    fr: "Devenir un distributeur",
    ht: "Vin yon distribitè"
  },
  message: {
    en: "Message",
    fr: "Message",
    ht: "Mesaj"
  },
  send: {
    en: "Send",
    fr: "Envoyer",
    ht: "Voye"
  },
  sentMessage: {
    en: "Message sent",
    fr: "Message envoyé",
    ht: "Mesaj voye",
  },
};

export { translates };
