import * as React from "react";

import { RichText } from "prismic-reactjs";
import { useRouteMatch } from "react-router-dom";

const BannerContent = ({
  data: {
    home_page_title,
    home_page_subtitle,
    home_page_background_image,
    ...extra
  }
}) => {
  const { path } = useRouteMatch();

  React.useEffect(() => {
    if (!path.includes("home")) return;
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 200);
  }, [path]);

  return (
    <section
      className="inner-page-banner has_bg_image"
      id="home"
      data-background={home_page_background_image?.url}
      style={{ backgroundImage: `url(${home_page_background_image?.url})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="inner-page-banner-area">
              <h1 className="title">{RichText.asText(home_page_title)}</h1>
              <nav aria-label="breadcrumb" className="page-header-breadcrumb">
                <RichText render={home_page_subtitle} />
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { BannerContent };
