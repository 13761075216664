import { createMuiTheme } from '@material-ui/core';

const boletTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#FF9800',
    },
    secondary: {
      main: '#607D8B',
    },
    divider: '#BDBDBD',
    text: {
      primary: '#212121',
      secondary: '#757575',
    },
  },
});

export { boletTheme };
