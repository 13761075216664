import styled, { keyframes } from "styled-components";

const unfold = keyframes`
    0% {
      transform: rotateX(0deg);
    }
    100% {
      transform: rotateX(-180deg);
    }
`;

const fold = keyframes`
    0% {
      transform: rotateX(-180deg);
    }
    100% {
      transform: rotateX(0deg);
    }
`;

const Wrapper = styled.div``;

const AnimatedWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
  backface-visibility: hidden;

  &.unfold {
    top: 50%;
    align-items: flex-start;
    transform-origin: 50% 0%;
    transform: rotateX(180deg);
    background-color: white;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border: 0.5px solid whitesmoke;
    border-top: 0.5px solid whitesmoke;

    span {
      transform: translateY(-50%);
    }

    animation: ${unfold} 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transform-style: preserve-3d;
  }

  &.fold {
    top: 0%;
    align-items: flex-end;
    transform-origin: 50% 100%;
    transform: rotateX(0deg);
    background-color: white;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border: 0.5px solid whitesmoke;
    border-bottom: 0.5px solid whitesmoke;

    span {
      transform: translateY(50%);
    }

    animation: ${fold} 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transform-style: preserve-3d;
  }
`;

const StaticWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 50%;
  overflow: hidden;
  border: 1px solid whitesmoke;

  &.lowerCard {
    align-items: flex-start;
    border-top: 0.5px solid whitesmoke;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    span {
      transform: translateY(-50%);
    }
  }

  &.upperCard {
    align-items: flex-end;
    border-top: 0.5px solid whitesmoke;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    span {
      transform: translateY(50%);
    }
  }
`;

const FlipUnitContainerWrapper = styled.div`
  display: block;
  position: relative;
  width: 120px;
  height: 80px;
  perspective-origin: 50% 50%;
  perspective: 300px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 10px 10px -10px grey;
  margin-right: 8px;
  margin-bottom: 8px;
`;

export const FlipClockWrapper = styled.div`
  display: flex;
  justify-content: center;

  & span {
    font-size: 4em;
    font-weight: lighter;
    color: var(--dark-main-color);
  }
`;

const AnimatedCard = ({ animation, digit }) => {
  return (
    <AnimatedWrapper className={`flipCard ${animation}`}>
      <span>{digit}</span>
    </AnimatedWrapper>
  );
};

const StaticCard = ({ position, digit }) => {
  return (
    <StaticWrapper className={position}>
      <span>{digit}</span>
    </StaticWrapper>
  );
};

const FlipUnitContainer = ({ digit, title }) => {
  const current = digit;
  const previous = `${parseInt(digit) + 1}`.padStart(2, "0");
  const shuffle = digit % 2 === 0;

  const animation1 = shuffle ? "fold" : "unfold";
  const animation2 = !shuffle ? "fold" : "unfold";

  const digit1 = shuffle ? previous : current;
  const digit2 = !shuffle ? previous : current;

  return (
    <Wrapper>
      <FlipUnitContainerWrapper>
        <StaticCard position={"upperCard"} digit={previous} />
        <StaticCard position={"lowerCard"} digit={current} />
        <AnimatedCard digit={digit2} animation={animation1} />
        <AnimatedCard digit={digit1} animation={animation2} />
      </FlipUnitContainerWrapper>
      {title}
    </Wrapper>
  );
};

export { FlipUnitContainerWrapper, FlipUnitContainer };
