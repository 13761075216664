import * as React from "react";

import { useFindByDocumentId } from "hooks/useFindByDocumentId";
import { Loading } from "components/Loading";
import { BannerContent } from "./BannerContent";
import { AboutContent } from "./AboutContent";
import { ContactContent } from "./ContactContent";

const Home = () => {
  const { result, loading } = useFindByDocumentId({
    type: "home_page"
  });

  if (loading || !result) {
    return <Loading />;
  }

  return (
    <>
      <BannerContent data={result?.data} />
      <AboutContent data={result?.data} />
      <ContactContent />
    </>
  );
};

export { Home };
