import * as React from 'react'

import { Link } from 'react-router-dom'
import { translates } from './translates'
import { useTranslations } from 'hooks/useTranslations'
import background from 'assets/images/inner-page-bg.png'
import bg1 from 'assets/images/bg-one.jpg'

import { Draw } from './Draw'
import { Loading } from 'components/Loading'

const servicesURL = 'https://webservices-internal.prod.idealoto.cc/v1/current/draws/results'
// 'https://internal.falseideal.com/v1/current/draws/results'

const Results = () => {
  const [result, setResult] = React.useState(null)
  const { translations, lang } = useTranslations({ translates })

  const getData = React.useCallback(async () => {
    try {
      const data = await fetch(servicesURL,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      const response = await data.json()
      setResult(response)
    } catch (e) {
      setResult(null)
    }
  }, [])

  React.useEffect(() => {
    setInterval(function timeChecker() {
      var oldTime = timeChecker.oldTime || new Date(),
        newTime = new Date(),
        timeDiff = newTime - oldTime

      timeChecker.oldTime = newTime

      if (Math.abs(timeDiff) >= 5000) {
        getData()
      }
    }, 500)

    getData()
  }, [getData])

  if (!result) return <Loading />

  const { comming_draws = [], results = [], current_timestamp } = result

  return (
    <>
      <section
        className="inner-page-banner has_bg_image"
        data-background={background}
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-page-banner-area">
                <h1 className="page-title">{translations?.results}</h1>
                <nav aria-label="breadcrumb" className="page-header-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={`/${lang}/home`}>{translations?.home}</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      {translations?.results}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="section-header text-center" style={{ marginTop: 15 }} />
      <div id="accordionGroup">
        {comming_draws.map((draw, index) => (
          <Draw
            key={draw.draw_id}
            draw={draw}
            timestamp={current_timestamp}
            index={index}
            onComplete={getData}
          />
        ))}
      </div>
      <section
        className="lottery-result-section section-padding has_bg_image"
        data-background={bg1}
        style={{ backgroundImage: `url(${bg1})` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="section-header text-center">
                <h2 className="section-title">{translations?.latestResults}</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="lottery-winning-num-part">
                <div className="lottery-winning-num-table">
                  <h3 className="block-title" style={{ paddingBottom: 0 }}>
                    {translations?.lotteryWinningNumber}
                  </h3>
                  <div className="container">
                    <table>
                      <tbody>
                        {results?.map(
                          ({ draw_id, draw_name, draw_ballots }) => (
                            <tr key={draw_id} className="ml-1">
                              <td>
                                <div className="winner-details">
                                  <span className="winner-name">
                                    {draw_name}
                                  </span>
                                </div>
                              </td>
                              <td className="col-lg-6 col-sm-6">
                                <ul className="number-list">
                                  {draw_ballots
                                    ?.split('-')
                                    .map((key, index) => (
                                      <li
                                        key={index}
                                        className={
                                          index === 0 ? 'active' : undefined
                                        }
                                      >
                                        {key}
                                      </li>
                                    ))}
                                </ul>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export { Results }
