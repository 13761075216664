const translates = {
  tchala: {
    en: 'Tchala',
    fr: 'Tchala',
    ht: 'Tchala',
  },
  keywords: {
    en: 'Keywords',
    fr: 'Mots Clés',
    ht: 'Mo kle',
  },
  results: {
    en: 'Results',
    fr: 'Résultats',
    ht: 'Rezilta',
  },
  searchKeywords: {
    en: 'Search Keywords',
    fr: 'Rechercher Mots Clés',
    ht: 'Rechèch Mo kle',
  },
  searchResults: {
    en: 'Search Results',
    fr: 'Rechercher Résultats',
    ht: 'Rechèch Rezilta',
  },
  search: {
    fr: 'Rechercher',
    ht: 'Rechèch',
    en: 'Search',
  },
};

export { translates };
