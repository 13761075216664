import React from "react";

import Prismic from "prismic-javascript";
import { prismic } from "utils/prismic";
import { useInternationalRoute } from "./useInternationalRoute";

const useFindByDocumentId = ({ documentId, type }) => {
  const cache = React.useRef({});
  const { lang } = useInternationalRoute();
  const [loading, setLoading] = React.useState(true);
  const [result, setResult] = React.useState(null);

  const language = React.useMemo(() => {
    switch (lang) {
      case "en":
        return "en-us";
      case "ht":
        return "ht-ha";
      default:
        return "fr-fr";
    }
  }, [lang]);

  const handleSetResult = React.useCallback(
    ({ results = [] }) => {
      if (!results.length) return;
      const [result] = results;
      setResult(result);
      if (!cache.current[language]) cache.current[language] = result;
    },
    [language]
  );

  React.useEffect(() => {
    if (cache.current[language]) {
      handleSetResult({ results: [cache.current[language]] });
      return;
    }
    setLoading(true);
    prismic
      .query(
        Prismic.Predicates.at(
          type ? "document.type" : "document.id",
          type || documentId
        ),
        {
          lang: language
        }
      )
      .then(handleSetResult)
      .finally(() => setLoading(false));
  }, [handleSetResult, documentId, language, type]);

  return { loading, result };
};

export { useFindByDocumentId };
