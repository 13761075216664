const translates = {
  aboutBoletIdeal: {
    en: "About Bolet ideal",
    fr: "À propos de Bolet Ideal",
    ht: "Sou Bolet ideal"
  },
  aboutUs: {
    en: "About us",
    ht: "Kiyès nou ye",
    fr: "Qui sommes-nous"
  },
  contactUs: {
    en: "Contact us",
    ht: "Kontakte nou",
    fr: "Contactez-nous"
  },
  quickLinks: {
    en: "Quick links",
    fr: "Liens rapides",
    ht: "Lyen rapid"
  },
  results: {
    en: "Results",
    fr: "Résultats",
    ht: "Rezilta"
  },
  history: {
    en: "History",
    fr: "Historique",
    ht: "Istorik"
  },
  prediction: {
    en: "Prediction",
    fr: "Tchala",
    ht: "Tchala"
  },
  faq: {
    en: "FAQ",
    fr: "FAQ",
    ht: "Kesyon"
  },
  emailNewsletters: {
    en: "Email Newsletters",
    fr: "Bulletins d'information par courrier électronique",
    ht: "Imèl Bilten"
  },
  newsletters: {
    en:
      "Subscribe now and receive weekly newsletter for latest draw and offer news and much more!",
    ht:
      "Abònman kounye a epi resevwa bilten chak semèn pou dènye trase epi ofri nouvèl ak plis ankò!",
    fr:
      "Inscrivez-vous dès maintenant et recevez la lettre d'information hebdomadaire sur les derniers tirages, les offres et bien plus encore!"
  },
  subscribe: {
    en: 'Subscribe',
    fr: 'Inscrivez-vous',
    ht: 'Abònman',
  }
};

export { translates };
