import React from "react";

import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { ScrollToTop } from "components/ScrollToTop";
import { SupportBar } from "components/SupportBar";
import { HeaderBar } from "components/HeaderBar";
import { Footer } from "components/Footer";
import { Home } from "views/Home";
import { useInternationalRoute } from "hooks/useInternationalRoute";
import { AboutUs } from "views/AboutUs";
import { History } from "views/History";
import { Prediction } from "views/Prediction";
import { Faq } from "views/Faq";
import { Results } from "views/Results";

function App() {
  const { pathname } = useLocation();
  const { getRoute } = useInternationalRoute();

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [pathname]);

  return (
    <>
      <div className="main-light-version">
        <header className="header-section">
          <SupportBar />
          <HeaderBar />
        </header>
        <Switch>
          <Route path={[getRoute("/home"), getRoute("/contact-us")]}>
            <Home />
          </Route>
          <Route path={getRoute("/history")}>
            <History />
          </Route>
          <Route path={getRoute("/about-us")}>
            <AboutUs />
          </Route>
          <Route path={getRoute("/tchala")}>
            <Prediction />
          </Route>
          <Route path={getRoute("/faq")}>
            <Faq />
          </Route>
          <Route path={getRoute("/results")}>
            <Results />
          </Route>
          <Route path="*">
            <Redirect to={getRoute("/home")} />
          </Route>
        </Switch>
        <Footer />
      </div>
      <ScrollToTop />
    </>
  );
}

export default App;
