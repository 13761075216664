import styled from "styled-components";

const square = ({ size }) => `
  height: ${size}px;
  width:  ${size}px;
`;

const borderRadius = ({ radius = "50%" }) => `
  border-radius: ${radius};
`;

const BallWrapper = styled.section`
  ${square}
  ${borderRadius}
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BallContent = styled.div`
  ${square}
  ${borderRadius}
  position: relative;
  overflow: hidden;
  background-color: var(--main-color);
  box-shadow: inset -10px -10px 0px 0px var(--main-color),
    0px 9px 30px 0px rgba(0, 0, 255, 0.15);
  color: #0f0f0f;
`;

const InnerBall = styled.div`
  width: ${({ size }) => size}px;
  position: relative;
  padding: ${({ size }) => size * 0.15}px;
  margin-top: 0;
  text-align: center;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;

  & > div {
    ${borderRadius};
    ${square}
    border: ${({ size }) => size * 0.03}px solid #fff;
    display: flex;
    margin-bottom: ${({ size }) => size * 0.15}px;
    justify-content: center;
    align-items: center;
    color: rgb(51, 55, 69);

    span {
    ${borderRadius};
    ${({ size }) => square({ size: size * 0.8 })}
      background: #fff;
      font-size: ${({ size }) => size * 0.5}px;
      display: block;
      font-weight: 800;
      line-height: ${({ size }) => size * 0.8}px;
    }
  }
`;
/*
         *
         *   &.ball-ani  {
    animation: ${({ size }) => ballAnimation({ size, totalBallots: 9 })} ${({
  speed
}) => speed * 9}s infinite alternate;
  }
  */

export { BallWrapper, BallContent, InnerBall };
