import * as React from 'react';
import { LanguageContext } from 'components/InternationalizationRoute';
import { useRouteMatch } from 'react-router-dom';

const useInternationalRoute = () => {
  const { url } = useRouteMatch();
  const { lang } = React.useContext(LanguageContext);

  const getRoute = (path) => {
    return `/${lang}${path}`;
  };

  const buildPath = (path) => `${url}/${path}`;

  return {
    getRoute,
    buildPath,
    lang,
  };
};

export { useInternationalRoute };
