import React from 'react';
import { BallWrapper, BallContent, InnerBall } from './Styles';

const Ballot = ({ value, size }) => {
  return (
    <BallWrapper size={size * 1.5} id={value}>
      <BallContent size={size * 1.3}>
        <InnerBall size={size}>
          <div>
            <span>{value}</span>
          </div>
        </InnerBall>
      </BallContent>
    </BallWrapper>
  );
};

export { Ballot };
