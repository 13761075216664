import * as React from 'react'
import ReactCountdown from 'react-countdown'
import { useTranslations } from 'hooks/useTranslations'

import { FlipClockWrapper, FlipUnitContainer } from './Styles'
import { translates } from './translates'

const Countdown = ({ date, onComplete, timestamp }) => {
  const { translations } = useTranslations({ translates })

  return (
    <ReactCountdown
      date={date}
      onComplete={() => onComplete(true)}
      renderer={({ hours, minutes, seconds, api }) => {
        return (
          <FlipClockWrapper>
            <FlipUnitContainer
              digit={`${hours}`.padStart(2, '0')}
              title={translations?.hours}
            />
            <FlipUnitContainer
              digit={`${minutes}`.padStart(2, '0')}
              title={translations?.minutes}
            />
            <FlipUnitContainer
              digit={`${seconds}`.padStart(2, '0')}
              title={translations?.seconds}
            />
          </FlipClockWrapper>
        )
      }}
    />
  )
}

export { Countdown }
