const translates = {
  home: {
    en: "Home",
    fr: "Acceuil",
    ht: "Lakay"
  },
  aboutUs: {
    en: "About us",
    fr: "À propos de nous",
    ht: "Sou nou"
  },
  results: {
    en: "Results",
    fr: "Résultats",
    ht: "Rezilta"
  },
  history: {
    en: "History",
    fr: "Historique",
    ht: "Istorik"
  },
  prediction: {
    en: "Tchala",
    fr: "Tchala",
    ht: "Tchala"
  },
  faq: {
    en: "FAQ",
    fr: "FAQ",
    ht: "FAQ"
  },
  contactUs: {
    en: "Contact us",
    ht: "Kontakte nou",
    fr: "Contactez-nous"
  }
};

export { translates };
